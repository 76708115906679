html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-color: $background-main;
  color: white;
  font-family: "Ubuntu", sans-serif;
  height: 100%;
  width: 100%;
}

#root {
  position: relative;
  height: 100%;
}
canvas#background-canvas {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
canvas.confetti {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

// // Basic Styling
// .flex {
//   display: flex;
// }
// .justify-center {
//   justify-content: center;
// }
// .align-center {
//   align-items: center;
// }
// .pointer {
//   cursor: pointer;
// }
// .pos-relative {
//   position: relative;
// }
// .pos-absolute {
//   position: absolute;
// }
// .text-elipsis { // wrap text
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
// // remove selection and borders on click
// // https://stackoverflow.com/a/21003770/6272061
// .no-select {
//   outline: 0;
//   -webkit-tap-highlight-color: transparent;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }
// .underscore {
//   text-decoration: underline;
// }
.remove-all-link-styling {
  outline: 0;
  text-decoration: none;
  color: white;
}
.bold-on-hover:hover {
  font-weight: bold;
}
.underscore-on-hover:hover {
  text-decoration: underline;
}
.unset {
  all: unset;
}