html, body {
  height: 100%; // to fix viewport issues on mobile!
}

.home-container {
  width: 100%;
  height: 100%;

  .nav {
    user-select: none;
    display: flex;
    justify-content: flex-start;
    div {
      margin-right: 60px;
    }
    .selected {
      font-weight: bold;
    }
  }
  
  .home-content {
    color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    height: calc(100% - 4rem);

    header {
      opacity: 0; // disable for now // @TODO
    }

    section {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h1.title-text {
        font-size: 5vw;
        width: 55%;
        user-select: none;
  
        span.light-grey {
          color: $light-grey;
        }
      }
    }

    footer {
      .send-text {
        margin-right: 20px;
      }
      user-select: none;
      .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .color-container {
          display:flex;
          
          .color-circle {
            -webkit-tap-highlight-color: transparent;
            width: 9vw;
            height: 9vw;
    
            background: red;
            border-radius: 50%;
            margin: 2px;
            cursor: pointer;
            display: none; // for screens <300, dont show confetti buttons
          }
          .color-orange {
            background: $orange;
            &:hover {
              box-shadow: 0px 5px 40px 0px $orange;
            }
          }
          .color-blue {
            background: $blue;
            &:hover {
              box-shadow: 0px 5px 40px 0px $blue;
            }
          }
          .color-red {
            background: $red;
            &:hover {
              box-shadow: 0px 5px 40px 0px $red;
            }
          }
          .color-pink {
            background: $pink;
            &:hover {
              box-shadow: 0px 5px 40px 0px $pink;
            }
          }
          .color-yellow {
            background: $yellow;
            &:hover {
              box-shadow: 0px 5px 40px 0px $yellow;
            }
          }
        }
      }
      .footer-left {
        display: flex;
        align-items: center;
      }
      .social-icon {
        display: none;
        padding:20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        &:hover {
          color: $yellow;
        }
      }
    }
  }
}

@media (min-width: 0px) {
  h1.title-text {
    width: 100% !important;
    font-size: 8vw !important;
  }
}
@media (min-width: 300px) {
  .home-container {
    .color-orange, .color-blue, .color-red {
      display:inline !important;
    }
  }
}
@media (min-width: 360px) {
  .home-container .color-circle {
    margin: 8px !important;
  }
}

@media (min-width: 768px) {
  .home-content {
    padding: 4rem !important;
    height: calc(100% - 8rem) !important;
  }
  h1.title-text {
    font-size: 67px;
    width: 100%;
    width: 100% !important;
    font-size: 9vw;
  }
  .color-container {
    display:flex !important;
  }
  .color-circle {
    width: 60px !important;
    height: 60px !important;
  }
  .color-pink, .color-yellow {
    display:inline !important;
  }
  footer p.send-text {
    font-size: 22px !important;
  }
}

// @media (min-width: 1366px) {
@media (min-width: 1000px) {
  h1.title-text {
    font-size: 67px !important;
    width: 67rem;
  }
  .footer-left .social-icon {
    display:inline !important;
  }
}

.home-container .send-text:hover,
.bold-on-hover:hover {
  // font-weight: bold;
  text-shadow: 0 0.015em #ffffff,0 -0.015em #ffffff,0.01em 0 #ffffff,-0.01em 0 #ffffff;
  -webkit-tap-highlight-color: transparent;
}